import request from "./request.js";

// 购物车列表
export function shopCart_list(data) {
	return request({
		url: "/web/shopCart/list",
		method: "post",
		data: data,
	});
}

// 添加购物车
export function shopCart_add(data) {
	return request({
		url: "/web/shopCart/save",
		method: "post",
		data: data,
	});
}

//设备预定
export function device(data) {
	return request({
		url: "/web/order/device",
		method: "post",
		data: data,
	});
}

//设备信息修改
export function edit(data) {
	return request({
		url: "/web/shopCart/edit",
		method: "post",
		data: data,
	});
}

//设备删除
export function shopCart_del(data) {
	return request({
		url: "/web/shopCart/delete",
		method: "post",
		data: data,
	});
}
