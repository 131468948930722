<template>
  <div class="shop-cart">
    <Header />
    <div class="container">
      <div class="w-1200">
        <div class="shop-cart-main">
          <div class="shop-cart-title">购物车</div>
          <template v-if="shopCartList.length !== 0">
            <section
              class="device-list"
              v-for="shopCart in shopCartList"
              :key="shopCart.id"
            >
              <div class="device-list-item" v-loading="loading">
                <div class="device-list-header">
                  <el-checkbox
                    v-model="shopCart.checked"
                    @change="shopCartChange(shopCart)"
                  ></el-checkbox>
                  <img
                    :src="shopCart.item[0].consumerHead"
                    class="avatar"
                    alt=""
                  />{{ shopCart.company_name }}
                </div>
                <div class="device-info-list">
                  <div
                    class="device-info-item"
                    v-for="(shopCartItem, index) in shopCart.item"
                    :key="index"
                  >
                    <div class="checkbox">
                      <span @click.stop>
                        <el-checkbox
                          v-model="shopCartItem.checked"
                          @change="
                            shopCartChange(shopCart.item, shopCart.company_name)
                          "
                        ></el-checkbox>
                      </span>
                    </div>
                    <img
                      :src="shopCartItem.showImg"
                      class="pic"
                      alt=""
                      @click="toDeviceDetail(shopCartItem.device_lease_id)"
                    />
                    <div class="content">
                      <div
                        class="name"
                        @click="toDeviceDetail(shopCartItem.device_lease_id)"
                      >
                        {{ shopCartItem.name }}
                      </div>
                      <div class="tag">
                        {{ shopCartItem.type_name }}
                      </div>
                    </div>
                    <span @click.stop>
                      <el-select
                        v-model="shopCartItem.rental_fee"
                        placeholder="请选择"
                        style="width: 130px"
                        @change="
                          rentalChange({
                            id: shopCartItem.id,
                            fee: shopCartItem.rental_fee,
                          })
                        "
                      >
                        <el-option
                          v-for="(
                            item, index
                          ) in shopCartItem.device_rental_fee_json"
                          :key="index"
                          :label="item.rental"
                          :value="`${item.rental_fee}/${item.rental_unit}`"
                        ></el-option>
                      </el-select>
                    </span>

                    <div class="ml40">
                      租赁时长（{{ shopCartItem.rental_type }}）
                      <span @click.stop>
                        <el-input-number
                          v-model="shopCartItem.rental_time"
                          size="mini"
                          style="width: 94px"
                          @change="
                            rentalChange({
                              id: shopCartItem.id,
                              time: shopCartItem.rental_time,
                            })
                          "
                          :min="1"
                          :max="999"
                          label="描述文字"
                        ></el-input-number>
                      </span>
                    </div>
                    <div class="ml40">
                      租赁台数
                      <span @click.stop>
                        <el-input-number
                          v-model="shopCartItem.rental_num"
                          size="mini"
                          @change="
                            rentalChange({
                              id: shopCartItem.id,
                              num: shopCartItem.rental_num,
                            })
                          "
                          style="width: 94px"
                          :min="1"
                          :max="shopCartItem.num"
                          label="描述文字"
                        ></el-input-number>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </template>
          <div v-else-if="shopCartList.length === 0" style="text-align: center">
            暂无数据
          </div>

          <div class="shop-cart-footer">
            <el-checkbox v-model="allChecked" @change="allCheck(allChecked)"
              >全选</el-checkbox
            >
            <div>
              <div class="del-btn" @click="deviceDelete">删除设备</div>
              <div class="base-btn" @click="deviceReserve">预定设备</div>
            </div>
          </div>
        </div>
        <div class="pagination">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            layout="total,prev, pager, next,jumper"
            :total="total"
            :current-page="page"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <Footer />
    <right-nav />
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import RightNav from "@/components/rightNav";
import { shopCart_list, edit, shopCart_del } from "@/api/shopCart";
import { Message } from "element-ui";
import kits from "@/kits/index";
export default {
  components: {
    Header,
    Footer,
    RightNav,
    [Message.name]: Message,
  },
  data() {
    return {
      num: 1,
      allChecked: false,
      shopCartList: [],
      page: 1,
      total: 0,
      checkedList: [],
      loading: false,
    };
  },
  mounted() {
    this.getShopCartList();
  },
  watch: {
    shopCartList: {
      // 监听的对象数组
      handler(newVal) {
        newVal.forEach((company) => {
          company.item.forEach((item) => {
            item.rental_type = item.rental_fee.split("/")[1];
          });
        });
        console.log("watch shopCartList", this.page, newVal);
        // if (this.page > 1 && newVal.length === 0) {
        // 	this.page -= 1
        // 	this.allChecked = false
        //     this.getShopCartList(this.page);
        // }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    //租赁信息改变
    rentalChange({ id, fee, time, num }) {
      console.log(`id ${id},fee ${fee},time ${time},num ${num}`);
      edit({
        inputs: {
          id: id,
          rental_fee: fee,
          rental_time: time,
          rental_num: num,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            if (res.data === 1) {
              return;
            } else {
              this.$message({
                message: "修改失败",
                type: "error",
              });
            }
          } else if (res.status === 500) {
            this.$message({
              message: "未知错误，请联系客服",
              type: "error",
            });
          } else {
            this.$message({
              message: "修改失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.$message({
            message: `${err}`,
            type: "error",
          });
        });
    },
    //跳转设备详情
    toDeviceDetail(device_lease_id) {
      this.$router.push({
        name: "deviceDetails",
        query: {
          id: device_lease_id,
        },
      });
    },
    //获取已勾选列表
    getCheckedList() {
      this.checkedList = [];
      this.shopCartList.forEach((shopCart) => {
        //商家checked为true 则直接推入checkedList
        if (shopCart.checked) {
          this.checkedList.push(shopCart);
          //商家checked为false 需要判断该商家的商品 商品checked为true 并且 checkedList无该商家 则将商家推入checkedList
        } else if (!shopCart.checked && shopCart.item !== []) {
          shopCart.item.forEach((shopCartItem) => {
            if (
              shopCartItem.checked &&
              this.checkedList.indexOf(shopCart) === -1
            ) {
              this.checkedList.push(shopCart);
            }
          });
        }
      });

      // 将checkedList中 checked为true的商品过滤出来
      this.checkedList.forEach((company) => {
        company.item = company.item.filter((item) => {
          return item.checked === true;
        });
      });
      console.log("checkedList", this.checkedList);
    },
    //设备删除
    deviceDelete() {
      this.getCheckedList();
      if (this.checkedList.length === 0) {
        this.$message({
          message: "请最少选中一个商品",
          type: "error",
        });
      } else {
        console.log("deviceDelete", this.checkedList);
        this.checkedList.forEach((company) => {
          company.item.forEach((item) => {
            shopCart_del({ inputs: { id: item.id } })
              .then((res) => {
                if (res.status === 200) {
                  if (res.data === 1) {
                    this.getShopCartList(this.page);
                    this.$message({
                      message: "删除成功",
                      type: "success",
                    });
                  } else {
                    this.$message({
                      message: "删除失败",
                      type: "error",
                    });
                  }
                } else if (res.status === 500) {
                  this.$message({
                    message: "未知错误，请联系客服",
                    type: "error",
                  });
                } else {
                  this.$message({
                    message: "删除失败",
                    type: "error",
                  });
                }
              })
              .catch((err) => {
                this.$message({
                  message: `${err}`,
                  type: "error",
                });
              });
          });
        });
      }
    },
    //设备预定
    deviceReserve() {
      this.getCheckedList();
      //提示判断
      if (this.checkedList.length === 0) {
        this.$message({
          message: "请最少选中一个商品",
          type: "error",
        });
      } else {
        localStorage.setItem(
          "deviceReserve_Key",
          JSON.stringify(this.checkedList)
        );
        this.$router.push({ name: "deviceReserve" });
      }
      console.log("deviceReserve", this.checkedList);
    },
    //全选
    allCheck(newVal) {
      this.shopCartList.forEach((shopCart) => {
        shopCart.checked = newVal;
        this.shopCartChange(shopCart);
      });
    },
    //勾选
    shopCartChange(shopCart, company_name) {
      event.stopPropagation();
      console.log("shopCartChange", shopCart);
      //勾选商户名即勾选该商户所有商品
      if (shopCart.item) {
        shopCart.item.forEach((item) => {
          item.checked = shopCart.checked;
        });
        console.log("勾选所有商品");
        //商户名全勾选时 全选勾选
        let allChecked = this.shopCartList.every((item) => {
          return item.checked === true;
        });
        this.allChecked = allChecked;
      } else {
        console.log("商品", shopCart, company_name);
        //商品全勾选时 商户名勾选
        let allItemChecked = shopCart.every((item) => {
          return item.checked === true;
        });
        this.shopCartList.find((item) => {
          if (item.company_name === company_name) {
            item.checked = allItemChecked;
          }
        });
        //商户名全勾选时 全选勾选
        let allChecked = this.shopCartList.every((item) => {
          return item.checked === true;
        });
        this.allChecked = allChecked;
      }
    },
    //换页
    handleCurrentChange(val) {
      this.getShopCartList(val);
      this.page = val;
    },
    //购物车列表
    getShopCartList(page = 1) {
      this.loading = true;
      console.log("getShopCartList()");
      shopCart_list({
        pagination: { pageNumber: page, pageSize: 10 },
      })
        .then((res) => {
          this.shopCartList = [];
          res.data.shopping_card_list.list !== [] &&
            res.data.shopping_card_list.list.forEach((shopping_card) => {
              shopping_card.showImg = kits.img.url(shopping_card.show_img);
              shopping_card.consumerHead = kits.img.url(
                shopping_card.consumer_head
              );
              shopping_card.checked = false;
              console.log("json0", shopping_card.device_rental_fee_json);
              shopping_card.device_rental_fee_json = JSON.parse(
                shopping_card.device_rental_fee_json
              );
              console.log("json", shopping_card.device_rental_fee_json);
              //拼接 2000/月
              shopping_card.device_rental_fee_json.forEach((item) => {
                item.rental_fee = parseFloat(item.rental_fee);
                item.rental_type = parseInt(item.rental_type);
                switch (item.rental_type) {
                  case 0:
                    item.rental_unit = "日";
                    break;
                  case 1:
                    item.rental_unit = "月";
                    break;
                  case 2:
                    item.rental_unit = "半年";
                    break;
                  case 3:
                    item.rental_unit = "年";
                    break;
                  default:
                    break;
                }
                item.rental = `${item.rental_fee}/${item.rental_unit}`;
              });

              //根据商家名分类
              let isHave = this.shopCartList.find((shopCart) => {
                return shopCart.company_name === shopping_card.company_name;
              });
              if (isHave) {
                this.shopCartList.find((shopCart) => {
                  if (shopCart.company_name === shopping_card.company_name) {
                    shopCart.item.push(shopping_card);
                  }
                });
              } else {
                let obj = {
                  company_name: shopping_card.company_name,
                  item: [shopping_card],
                  checked: false,
                };
                this.shopCartList.push(obj);
              }
            });
          console.log("this.shopCartList", this.shopCartList);
          this.total = res.data.total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$message({
            message: `${err}`,
            type: "error",
          });
        });
    },
  },
};
</script>

<style scoped>
.del-btn {
  height: 54px;
  padding: 0 12px;
  min-width: 172px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  transition: 0.3s;
  cursor: pointer;
  font-size: 20px;
  color: #ff992b;
  font-weight: bold;
  border-radius: 4px;
  margin-top: 46px;
  border: 1px solid #ff992b;
  margin-right: 20px;
}
.del-btn:hover {
  background-color: #fff9f3;
}
.del-btn:active {
  background-color: #fff9f3;
}
</style>
